const getBarLimits = (minRange: number, maxRange: number, total: number, operatingBG: string[]) => {
  const rangeValues = [minRange, maxRange];

  const scalesData = operatingBG
    .map((color: any, index: any) => ({ value: index, color }))
    .filter(
      (item: any, index: any) =>
        item.color !== operatingBG[index + 2] && item.color !== "transparent"
    );

  const _scales = scalesData.map((item: any, index: any) => ({
    value: item.value - (scalesData[index - 1]?.value || 0),
    color: item.color,
    point: rangeValues[index],
  }));

  const _scalesPoints =
    scalesData.length === 2
      ? [minRange === 0 ? maxRange : minRange]
      : scalesData.length === 3
        ? [minRange, maxRange]
        : [];
  return [_scalesPoints, _scales];
};

export default getBarLimits;