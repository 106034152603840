/* eslint-disable @typescript-eslint/no-unused-expressions */
import {leapApi as api} from "utilities/Axios";

export const updateAlarm = (tagId:number, status:number) => {
	return api.get(`/AlertTag/SetAlertStatus?tagId=${tagId}&status=${status}`);
};

export const getCount = () => {
	return api.get("/AlertTag/GetActiveAlertCount");
};

export const getAlarms = () => {
    return api.get("/AlertTag/GetAlertTagHistories");
};

export const updateAlarmHistory = (alarmId:number, status:number) => {
	return api.get(`/AlertTag/SetAlertHistoryStatus?tagHistoryId=${alarmId}&status=${status}`);
};

export const deleteAlarm = (alarmId:number) => {
    return api.get(`/AlertTag/DeleteAlertHistory?tagHistoryId=${alarmId}`);
};

export const AcceptAllAlarm = () => {
  return api.get("/AlertTag/AcceptAllAlerts");
};

export const deleteAllAlarm = () => {
  return api.get("/AlertTag/DeleteAllAlerts");
};

export const acceptAllAlarm = () => {
  return api.get("/AlertTag/AcceptAllAlerts");
};


// SET_TAG_ALARM_STATUS: '/AlertTag/SetAlertStatus',
// GET_ALARM_TAGS: '/AlertTag/GetAlertTagHistories',
// SET_ALARM_STATUS: '/AlertTag/SetAlertHistoryStatus',
// GET_ALARM_COUNT: '/AlertTag/GetActiveAlertCount',
// DELETE_ALARM: '/AlertTag/DeleteAlertHistory',