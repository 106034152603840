/* eslint-disable @typescript-eslint/no-unused-expressions */
import { leapApi as api } from "utilities/Axios";


export const getWidgets = async () => {
	return api.get("/Dashboard/GetDashboardWidget");
};

export const updateWidgets = async (data: any) => {
	return api.post("/Dashboard/UpdateDashboardWidget", data);
};

export const getAllData = async (plantId = 1) => {
	return api.get(
		`/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant?plantId=${plantId}`
	);
};

export const getTagsHealth = async () => {
  return api.get("/Dashboard/GetTagsHealth");
};

export const getExpressionPastData = async (
	id: string,
	days: number,
	avgType: string,
	dateTime?: string,
) => {
	let url = `/Dashboard/GetPastExpressionDataWithAvg?expressionId=${id}`;
	days ? (url += `&pastNumberOfDays=${days}`) : 1;
	avgType ? (url += `&avgType=${avgType}`) : null;
	dateTime ? (url += `&dateTime=${dateTime}`) : null;
	return api.get(url);
};

export const getExpressionFutureData = async (
	id: string,
	avgType: string
) => {
	let url = `/Dashboard/GetPastExpressionDataWithAvgFuture?expressionId=${id}`;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return api.get(url);
};

export const getTagsPastData = async (
	id: string,
	days: number,
	avgType: string,
	dateTime?: string,
) => {
	let url = `/Dashboard/GetTagPastDataWithAvg?tagId=${id}`;
	days ? (url += `&pastNumberOfDays=${days}`) : null;
	avgType ? (url += `&avgType=${avgType}`) : null;
	dateTime ? (url += `&dateTime=${dateTime}`) : null;
	return api.get(url);
};

export const getTagFutureData = async (
	id: string,
	avgType: string
) => {
	let url = `/Dashboard/GetTagPastDataWithAvgFuture?tagId=${id}`;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return api.get(url);
};

export const getPinnedWidgets = async () => {
	return api.get("/Dashboard/getPinnedWidgets");
};

export const pinWidget = async (data: any) => {
	return api.post(`/Dashboard/PinWidget`, data);
};

export const getExpressionsValues = async (data: any) => {
	return api.post(`/Dashboard/GetExpressionValue`, data);
};

export const getSystemLogs = ({ durType = 3, count = 10, action = null, module = null }) => {
	let url: any = `/SystemLog/GetSystemLogsByDateTime` + `?durType=${durType}&count=${count}`;
	if (module != null) {
		url += `&module=${module}`;
	}
	if (action != null) {
		url += `&action=${action}`;
	}

	return api.get(url);
};
