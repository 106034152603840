export const areaConfig = {
  regular: {
    maxTicksLimit: 7,
    days: 1,
  },
  unity: {
    maxTicksLimit: 4,
    days: 1,
  },
  small: {
    maxTicksLimit: 7,
    days: 1,
  },
  smallest: {
    maxTicksLimit: 4,
    days: 1,
  },
  yearly: {
    maxTicksLimit: 17,
    days: 365,
  }
};

export const barConfig = {
  big: {
    containerClasses: 'ml-2',
    iconSize: 22,
    buttonSize: '',
    buttonContainerClasses: 'mt-5 text-xl',
  },

  regular: {
    containerClasses: '',
    iconSize: 23,
    buttonSize: '',
    buttonContainerClasses: 'mt-2 text-xl',
  },

  small: {
    containerClasses: '',
    iconSize: 17,
    buttonSize: '',
    buttonContainerClasses: 'lg:mt-0.5 xl:mt-1.5 2xl:mt-3 lg:text-sm  2xl:text-xl ',
  }
};