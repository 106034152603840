import { GaugeOptions } from "../domain/types";

const getOptions = ({ fontSize, formattedValue }: GaugeOptions) => {
  return {
    plugins: {
      // gradient,
      datalabels: {
        color: function () {
          return "white";
        },
        display: true,
        font: {
          size: fontSize,
        },
        anchor: "start" as const,
      },
      tooltip: {
        yAlign: "bottom" as const,
        enabled: true,
        displayColors: false,
        callbacks: {
          label: function () {
            return `${formattedValue}`;
          },
        },
      },
    },
    rotation: -74,
    circumference: 150,
    cutout: "60%",
    responsive: true,
    animation: {
      duration: 0,
    },

    maintainAspectRatio: false,
  };
};

export default getOptions;