import {
  createContext,
  useContext,
  useState,
} from "react";

export const UnityContext = createContext<any | null>(null);

export const UnityProvider = ({ children }: { children: any }) => {
    const [timeline, settimeline] = useState(false);
    const [timelinebutton, settimelinebutton] = useState(false);


  return (
    <UnityContext.Provider value={{timeline, settimeline,timelinebutton, settimelinebutton}}>
      {children}
    </UnityContext.Provider>
  );
};

export default function useUnity() {
  return useContext(UnityContext);
}
