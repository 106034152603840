import React from "react";
import classNames from "utilities/ClassNames";
import { Link } from "react-router-dom";
import { ReactComponent as ProcessHubLogo } from "assets/logo.svg";
import { ReactComponent as LeapLogo } from 'assets/leap_logo.svg';
import TakamulLogo from 'assets/TT_logo_white.png';

const EmptyHeader = () => {

  const isScaled = window.devicePixelRatio > 1;

  return (
  <div className="w-full bg-dark relative z-40 outline">
    <div
      className={classNames(
        isScaled ? "h-20" : "h-16",
        "mx-auto w-full flex items-center px-3"
      )}
    >
      <div className="relative z-50 flex items-center gap-5 w-full">
        <Link to="/">
          <ProcessHubLogo className={classNames(isScaled ? "h-12" : "h-8")} />
        </Link>
      </div>
      <Link to="/" className="z-50">
        <div className={classNames(isScaled ? "h-[3.25rem] w-max text-2xl" : "h-[2.65rem]", "rounded-lg bg-gradient-to-r from-green to-blue w-full items-center justify-center z-50")}>
          <button className={classNames(isScaled ? "h-[3rem] w-max px-12" : "h-[2.40rem]", "text-center bg-gradient-to-r from-gray to-black rounded-lg w-40  hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 ")}>
            Reload Page
          </button>
        </div>
      </Link>
    </div>
    <div className={classNames(isScaled ? "h-[80px]" : "h-[64px]", "absolute top-0 w-full flex")}>
      <Link to="/" className="flex w-min items-center mx-auto">
        <LeapLogo className="h-8 text-white w-[240px]" />
        <span className="block text-xl mt-3 ml-1 font-bold">
          by
        </span>
        <img src={TakamulLogo} alt="Takamul Logo" className="w-28 ml-1.5" />
      </Link>
    </div>
  </div>);
};

export default EmptyHeader;