import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { DataProvider } from "hooks/useData";
import { ContextProvider } from "utilities/ContextProvider";
import { UnityProvider } from "hooks/UseUnity";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { ScreenProvider } from "hooks/useScreen";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_LEAP24_APP}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_LEAP24_APP}`,
  scope: 'openid',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  onSigninCallback: (user: any) => {
    window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
    window.location = user.state || "/";
  }
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppProvider>
        <UnityProvider>
          <ScreenProvider>
            <DataProvider>
              <ContextProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ContextProvider>
            </DataProvider>
          </ScreenProvider>
        </UnityProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
