import { deleteAllAlarm } from "api/alarm";

const deleteAllAlarmService = async () => {
  const response: any = await deleteAllAlarm();
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteAllAlarmService;