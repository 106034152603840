import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import classNames from "utilities/ClassNames";
import { Box } from "@mui/system";
import useApp from "hooks/useApp";

const LogDetails = (props: any) => {
  const { isScaled } = useApp();

  const genIconStyles = (bgColor: string) => {
    return classNames(
      isScaled ? "w-[43px] h-[43px]" : "w-[38px] h-[38px]",
      "rounded-full flex justify-center items-center",
      bgColor === "blue" ? 'bg-[#09289A]' : '',
      bgColor === "green" ? 'bg-[#099A52]' : '',
      bgColor === "grey" ? 'bg-grey' : '',
      bgColor === "lightBlue" ? 'bg-[#08AEB2]' : '',
      bgColor === "red" ? 'bg-[#9A0909]' : '',
    );
  };

  const iconSize = isScaled ? { width: "32px", height: "32px" } : {};

  return (
    <Box width={"100%"}>
      <div className={
        classNames(isScaled ? "text-3xl" : "text-2xl", "text-center border-b-2 border-[rgb(22,245,211)] py-2")
      }>Details</div>
      <div
        className="py-1 px-2"
        style={{
          borderBottom: "2px rgb(22,245,211) solid",
        }}
      >
        <div className="row" style={{ paddingTop: "7px", display: "flex", justifyContent: 'center' }}>
          {props.LogData.action === "Viewed" && (
            <div
              className={genIconStyles("grey")}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "white" }} />
            </div>
          )}
          {props.LogData.action === "Modified" && (
            <div
              className={genIconStyles("blue")}
            >
              <UpdateIcon
                style={{ ...iconSize, color: "white" }}
              />
            </div>
          )}
          {props.LogData.action === "Information" && (
            <div
              className={genIconStyles("lightBlue")}
            >
              <PriorityHighOutlinedIcon style={{ ...iconSize, color: "white" }} />
            </div>
          )}
          {props.LogData.action === "Created" && (
            <div
              className={genIconStyles("green")}
            >
              <AddIcon style={{ ...iconSize, color: "white" }} />
            </div>
          )}
          {props.LogData.action === "Deleted" && (
            <div
              className={genIconStyles("red")}
            >
              <DeleteIcon style={{ ...iconSize, color: "white" }} />
            </div>
          )}
          <div style={{ paddingLeft: "10px", paddingTop: "7px" }}>
            {props.LogData.module}
          </div>
        </div>
        <div className="row" style={{ paddingBottom: '10px' }}>
          <div style={{ flexWrap: "nowrap", flexDirection: "column" }}>
            <div>{props.LogData.at}</div>
          </div>
          <div className="col" style={{ display: 'flex', justifyContent: 'right' }}>{props.LogData.by}</div>
        </div>
        <div className="col" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>{props.LogData.desc}</div>
      </div>
      <pre
        style={{ marginTop: "50px", lineHeight: "25px", whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        className="p-2"
      >
        {props.LogData.detail}
      </pre>
    </Box>
  );
};
export default LogDetails;
