import React from "react";
import { cn } from "@/lib/utils";

export const DashboardChartContainer = ({
  type,
  position,
  children,
}: {
  type: number;
  position: number;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div
        className={cn(
          type === 2
            ? position === 5
              ? "lg:col-span-2"
              : "col-span-1"
            : type === 1
            ? position === 1
              ? "lg:col-span-2"
              : "col-span-1"
            : "","h-[44vh] lg:h-[44vh] xl-2xl:h-[44vh]",
          "w-full rounded-md gradient  p-0.5"
        )}
      >
        <div className="bg-black rounded-md w-full h-full">{children}</div>
      </div>
    </>
  );
};
