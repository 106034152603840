import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import classNames from "utilities/ClassNames";

export default function Layout() {
  const location = useLocation();
  const containerClasses = classNames(
    location.pathname === '/unity' ? "" : "bg-black",
    "pb-10 2xl:pb-0", "relative overflow-hidden w-full h-full max-w-[2800px] mx-auto"
  );
  return (
    <div className={containerClasses}>
      <Header />
      <Outlet />
    </div >
  );
}
