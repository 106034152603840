import { useState } from "react";
import classNames from "utilities/ClassNames";
import ActionModal from "./ActionModal/ActionModal";
import ChartModal from "./ChartModal/ChartModal";
import DangerModal from "./DangerModal/DangerModal";
import InfoModal from "./InfoModal/InfoModal";
import MappingModal from "./MappingModal/MappingModal";
import { ModalProps } from "./domain/types";

const Modal: React.FC<ModalProps> = ({ page, selectedTag, setSelectedTag, isLoading, refresh, isAlarm, listing }) => {
  if (selectedTag.action) {
    const { action } = selectedTag;
    const [isTicket, setIsTicket] = useState(false);
    const [isBigGraph, setIsBigGraph] = useState(false);
    const actionClasses: any = {
      chart: isBigGraph ? "w-[60%] h-[70vh] gradient" : isTicket ? "w-[35%] h-[70vh]" : "w-[50%] xl:w-[35%] h-max gradient",
      delete: "w-[20%] min-w-[300px]",
      reset: "w-[20%] min-w-[540px]",
      new: "w-[45%]",
      edit: "w-[60%] xl:w-[45%] min-w-[540px]",
      mapping: "w-[70%]"
    };

    const containerClasses = classNames(
      actionClasses[action] || "",
      "h-auto mx-auto p-0.5 rounded-md z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    );


    return (
      <div className="fixed inset-0 z-10 w-full h-screen">
        <div
          onClick={() => {
            if (!isTicket) {
              setSelectedTag({ action: "", value: null });
            }
            if (isTicket) {
              setIsTicket(false);
              setSelectedTag({ action: "chart", value: selectedTag.value });
            }
          }}
          className="h-full absolute z-20 inset-0 w-full bg-black/70"
        />
        <div className={containerClasses}>
          {(action === 'delete' || action === 'reset' || action === 'reject' || action === 'deleteAll') && <DangerModal type={action} page={page} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isLoading={isLoading} refresh={refresh} />}
          {(action === 'close' || action === 'acknowledge' || action === 'acknowledgeAll') && <InfoModal type={action} page={page} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isLoading={isLoading} refresh={refresh} />}
          {!isTicket && (action === 'chart' && (page === 'tags' || page === 'expressions' || page === 'alarms')) &&
            <ChartModal page={page} refresh={refresh} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isAlarm={isAlarm} setTicket={setIsTicket} setIsBigGraph={setIsBigGraph} isBigGraph={isBigGraph} />
          }
          {(action === 'edit' || action === 'new') && <ActionModal page={page} type={action} selectedTag={selectedTag} setSelectedTag={setSelectedTag} refresh={refresh} listing={listing} />}
          {(action === 'mapping') && <MappingModal listing={listing} setSelectedTag={setSelectedTag} refresh={refresh} />}
          {isTicket && <ActionModal page='tickets' type='new' selectedTag={selectedTag} setSelectedTag={setSelectedTag} refresh={refresh} />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;
