import { EditTabWidgetProps} from "pages/Dashboard/AllDynamicWidgets/domain/types";
import { EditConfirmationButtons } from "./EditConfirmationButtons";



export const EditTabWidget:React.FC<EditTabWidgetProps>=({tabError, tabMode, setScreen, setNewTab, setTabError, submit, children})=>{
    return(
        <>
            {children}
            {EditConfirmationButtons({tabError, tabMode, setScreen, setNewTab, setTabError,submit})}
        </>
    );
};