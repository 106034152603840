import { getEquipmentsByUnitId } from "api/equipments";
import {
  getTagsByEquipmentsId,
} from "api/tags";
import { getTrains as apiGetTrains } from "api/trains";
import { getUnitsByTrainId } from "api/units";
import { useEffect, useState } from "react";
import Asset from "components/Asset/Asset";
import classNames from "utilities/ClassNames";
import { Item, SelectedItem } from "types/common";
import Modal from "components/modal/Modal";
import { updateAlarm } from "api/alarm";
import { ListingProps, FetchAssetsDataProps, CheckSelectionProps } from "./domain/types";
import { TrainProps, UnitProps, EquipmentsProps } from "types/assetTypes";


export default function AllAssets() {
  const [tag, setTag] = useState<SelectedItem>({
    action: "",
    value: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState<"trains" | "tags" | "expressions" | "units" | "equipments">("trains");
  const [listing, setListing] = useState<ListingProps>(
    {
      selectedTrainId: null,
      selectedUnitId: null,
      selectedEquipmentId: null,
      selectedEquipment: null,
    }
  );

  // show status
  const [showUnits, setShowUnits] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [showTags, setShowTags] = useState(false);

  //   selected
  const [selectedTrain, setSelectedTrain] = useState<TrainProps | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<UnitProps | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentsProps | null>(null);

  //   data
  const [trains, setTrains] = useState<TrainProps[] | null>(null);
  const [units, setUnits] = useState<UnitProps[] | null>(null);
  const [equipments, setEquipments] = useState<EquipmentsProps[] | null>(null);
  const [tagsByEquipment, setTagsByEquipment] = useState<Item[] | null>(null);

  const getTrains = async () => {
    const response = await apiGetTrains();
    if (response.status === 200) {
      setTrains(response.data);
    }
    else setTrains(null);
  };

  const getUnits = async () => {
    const response = await getUnitsByTrainId(selectedTrain?.id);
    if (response.status === 200) {
      setUnits(response.data);
    }
  };

  const getEquipments = async () => {
    const response = await getEquipmentsByUnitId(selectedUnit?.id);
    if (response.status === 200) {
      setEquipments(response.data);
    }
    else setEquipments(null);
  };

  const getTagsById = async () => {
    const response = await getTagsByEquipmentsId(selectedEquipment?.id);
    if (response.status === 200) {
      setTagsByEquipment(response.data);
    }
    else setTagsByEquipment(null);
  };

  useEffect(() => {
    getTrains();
  }, []);

  const fetchData = async ({ fetchFunction, setShowFunction }: FetchAssetsDataProps) => {
    try {
      setIsLoading(true);
      await fetchFunction();
      setShowFunction(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTrain) {
      fetchData({ fetchFunction: getUnits, setShowFunction: setShowUnits });
    }
  }, [selectedTrain]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData({ fetchFunction: getEquipments, setShowFunction: setShowEquipments });
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedEquipment) {
      fetchData({ fetchFunction: getTagsById, setShowFunction: setShowTags });
    }
  }, [selectedEquipment]);

  useEffect(() => {
    if (selectedTrain || selectedUnit || selectedEquipment) {
      setListing({
        selectedTrainId: selectedTrain?.id || null,
        selectedEquipmentId: selectedEquipment?.id || null,
        selectedUnitId: selectedUnit?.id || null,
        selectedEquipment: selectedEquipment,
      }
      );
    }
  }, [selectedTrain, selectedUnit, selectedEquipment]);

  const checkSelection = <T extends TrainProps | UnitProps | EquipmentsProps>({ list, selectedItem, setSelected }: CheckSelectionProps<T>) => {
    if (list !== null) {
      const listIds = list.map((item: T) => item.id);
      if (!listIds.includes(selectedItem.id)) {
        setSelected(null);
      }
    }
  };
  useEffect(() => {
    if (trains && trains !== null && selectedTrain !== null) checkSelection({ list: trains, selectedItem: selectedTrain, setSelected: setSelectedTrain });
    if (units && units !== null && selectedUnit !== null) checkSelection({ list: units, selectedItem: selectedUnit, setSelected: setSelectedUnit });
    if (equipments && equipments !== null && selectedEquipment !== null) checkSelection({ list: equipments, selectedItem: selectedEquipment, setSelected: setSelectedEquipment });
  }, [trains, units, equipments]);

  useEffect(() => {
    if (selectedTrain === null) {
      setShowUnits(false);
      setShowEquipments(false);
      setShowTags(false);
    }
    if (selectedUnit === null) {
      setShowEquipments(false);
      setShowTags(false);
    }
    if (selectedEquipment === null) {
      setShowTags(false);
    }
  }, [selectedTrain, selectedUnit, selectedEquipment]);

  const refreshData = async () => {
    setIsLoading(true);
    await getTrains();
    await getUnits();
    await getEquipments();
    if (selectedEquipment) {
      await getTagsById();
    }
    setIsLoading(false);
  };

  const handleActionClick = (itemValue: Item, itemAction: 'chart' | 'edit' | 'reset' | 'delete') => {
    setTag({
      action: itemAction,
      value: itemValue
    });
  };

  const handleTagsClick = (itemValue: Item, itemAction: 'chart' | 'edit' | 'reset' | 'delete') => {
    setSelectedPage('tags');
    handleActionClick(itemValue, itemAction);
  };

  const onAssetsClick = (e: TrainProps) => {
    setSelectedTrain(e);
    setSelectedUnit(null);
    setShowUnits(true);
    setShowEquipments(false);
    setShowTags(false);
  };

  const onUnitsClick = (e: UnitProps) => {
    setSelectedUnit(e);
    setSelectedEquipment(null);
    setShowEquipments(true);
    setShowTags(false);
  };

  const onEquipmentsClick = (e: EquipmentsProps) => {
    setSelectedEquipment(e);
    setShowTags(true);
  };

  const onAddItem = () => {
    setTag({ action: "new", value: null });
  };

  const onMapping = () => {
    setTag({ action: 'mapping', value: null });
  };

  const alarm = async (id: number, isAlertTag: boolean) => {
    const response = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  return (
    <>
      <div className="p-3 flex flex-col gap-2 h-[85vh]">
        <div className="h-[50%]">
          <div className="w-full h-full grid grid-cols-3 grid-rows-1 gap-2">
            <Asset data={trains} isLoading={isLoading} type="trains" onClickHandler={onAssetsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedTrain} />
            {showUnits && <Asset data={units} isLoading={isLoading} type="units" onClickHandler={onUnitsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedUnit} />}
            {showEquipments && <Asset data={equipments} isLoading={isLoading} type="equipments" onClickHandler={onEquipmentsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedEquipment} />}
          </div>
        </div>
        <div className={classNames("mt-0", "h-[50%]")}>
          {showTags && <Asset data={tagsByEquipment} isLoading={isLoading} type="tags" onClickHandler={handleTagsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} onMapping={onMapping} setSelectedPage={setSelectedPage} />}
        </div>
      </div>
      <Modal page={selectedPage} isAlarm={alarm} selectedTag={tag} setSelectedTag={setTag} refresh={refreshData} isLoading={isLoading} listing={listing} />
    </>
  );
}
