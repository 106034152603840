import useScreen from "hooks/useScreen";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import classNames from "utilities/ClassNames";
import { Link } from "react-router-dom";
import { dashboardconfigmenu } from "./config/constants";

export default function DashboardConfiguration() {
  const { isScaled } = useScreen();
  const location = useLocation();
  const routes = location.pathname.split("/");

  return (
    <div>
      <div
        className={classNames(
          isScaled ? "text-2xl" : "",
          "pl-3 pt-5 2xl:pb-5 flex gap-5"
        )}
      >
        {dashboardconfigmenu.map((item: any, index: any) => (
          <div
            key={index}
            className={classNames(
              routes.includes(item.route) ? "gradient" : "bg-black",
              "flex py-0.5 xl:py-0.5"
            )}
          >
            <Link
              to={item.to}
              className="cursor-pointer font-bold w-full h-full bg-black px-2 pb-1 text-[13px] xl:text-sm 2xl:text-[16px] 4xl:text-lg"
            >
              {item.name}
            </Link>
          </div>
        ))}
      </div>
      <Outlet />
    </div>
  );
}
