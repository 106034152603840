import closeTicketService from "../domain/closeTicketService";
import acknowledgeAlarmService from "../domain/acknowledgeAlarmService";
import acknowledgeAllAlarmsService from "../domain/acknowledgeAllAlarmsService";

const infoModalConfig = {
  tags: {
    title: 'tag',
    close: {
      action: null
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  expressions: {
    title: 'expression',
    close: {
      action: null
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  trains: {
    title: 'trian',
    close: {
      action: null
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  units: {
    title: 'unit',
    close: {
      action: null
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  equipments: {
    title: 'equipment',
    close: {
      action: null
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  tickets: {
    title: 'ticket',
    close: {
      action: closeTicketService
    },
    acknowledge: {
      action: null
    },
    acknowledgeAll: {
      action: null
    }
  },
  alarms: {
    title: 'alarm',
    close: {
      action: null
    },
    acknowledge: {
      action: acknowledgeAlarmService
    },
    acknowledgeAll: {
      action: acknowledgeAllAlarmsService
    }
  }
};

export default infoModalConfig;