const arrayToCSV = (array: any) => {
  if (array.length === 0) {
    return '';
  }
  const csvRows = [];
  const headers = Object.keys(array[0]);
  csvRows.push(headers.join(','));
  for (const item of array) {
    const values = headers.map((header) => {
      const escaped = ('' + item[header]).replace(/"/g, '""');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};

export default arrayToCSV;