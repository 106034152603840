import { useState } from "react";
import AllConfiguration from "./ChartConfigurationTable";
import { getDefaultConfigurations } from "../../pages/Dashboard/AllDynamicWidgets/config/constants";
import { chartConfigurationsProps, configDataProps } from "../../pages/Dashboard/AllDynamicWidgets/domain/types";
import { EditTabWidget } from "components/DynamicWidgets/EditTabWidget";
import { cn } from "@/lib/utils";

export default function Configuration({
  widget,
  screen,
  setScreen,
  tab,
  tabs,
  currentTab,
  updateWidgets,
  setTabError,
  setNewTab,
  tabError,
}: any) {
  const [graph, setgraph] = useState<
    chartConfigurationsProps | configDataProps
  >(
    tab?.type && tab?.configurations
      ? tab?.configurations
      : getDefaultConfigurations(tab?.type)
  );
  const handleConfiguration = () => {

    const isGraphEmpty = (graph: chartConfigurationsProps | configDataProps) =>
      Object.values(graph).every(
        (value: configDataProps) =>
          value.id === "" && value.name === "" && value.value === ""
      );

    const handleEmptyGraph = () => {
      const newTab = { ...tab, configurations: null, configured: false };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
    };

    const handleNonEmptyGraph = () => {
      const newTab = { ...tab, configurations: graph, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
    };

    if (
      tab.type === "large" ||
      tab.type === "kpi" ||
      tab.type === "area" ||
      tab.type === "gauge" ||
      tab.type === "gaugeBar" ||
      tab.type === "oneGaugeBar" ||
      tab.type === "column"
    ) {
      if (tab.type === "area" && isGraphEmpty(graph)) {
        handleEmptyGraph();
      } else if (!isGraphEmpty(graph)) {
        handleNonEmptyGraph();
      } else {
        handleEmptyGraph();
      }
    }
  };

  return (
    <>
      {screen === "configuration" && (
        <div className="relative h-full w-full flex items-center justify-center">
          <div className=" w-full h-max">
            {tab.type && (
              <EditTabWidget
                tabError={tabError}
                tabMode={"new"}
                setScreen={setScreen}
                setNewTab={setNewTab}
                setTabError={setTabError}
                submit={handleConfiguration}
              >
                <AllConfiguration
                  graph={graph}
                  setgraph={setgraph}
                  graphtype={tab.type}
                />
              </EditTabWidget>
            )}
          </div>
        </div>
      )}
    </>
  );
}
