import React, { useState, useEffect, useCallback } from "react";
import useApp from "hooks/useApp";
import { Unity, useUnityContext } from "react-unity-webgl";
import useSignalR from "hooks/useSignalR";
import useData from "hooks/useData";
import { useAuth } from "react-oidc-context";
import { SplashScreen } from "./SplashScreen";
import WebGLContext from "./WebGLContext";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { getAllTagsWithParents } from "api/tags";
import { getAllExpressions } from "api/expression";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import UnityWidgetContainer from "components/UnityWidgetContainer/UnityWidgetContainer";

const WebGL = () => {
  const isScaled = useScreen();
  const { setIsLoggedIn, user } = useApp();
  const [unityLoaded, setUnityLoaded] = useState<any>(false);
  const [unityPrefabLoading, setUnityPrefabLoading] = useState(0);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState(0);
  const [fakeLoading, setFakeLoading] = useState(false);

  function handleCacheControl(url: any) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  function unityLoadingInterval() {
    const intervalId = setInterval(() => {
      setUnityLoadingProgress((prevProgress) => {
        if (prevProgress !== 99) {
          return prevProgress + 1;
        } else {
          clearInterval(intervalId);
          return prevProgress;
        }
      });
    }, 3000);
  }
  useEffect(() => {
    if (!fakeLoading) {
      console.log('PREFAB = ' + unityPrefabLoading);
      if (unityPrefabLoading < 90) {
        setUnityLoadingProgress(unityPrefabLoading);
      } else if (unityPrefabLoading === 90) {
        setUnityLoadingProgress(90);
        unityLoadingInterval();
        setFakeLoading(true);
      }
    }
  }, [unityPrefabLoading]);

  const unityProgressBarHandler: any = useCallback((amount: number) => {
    const loadingAmount = Math.round(90 * amount);
    setUnityPrefabLoading(loadingAmount);
  }, []);

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: window.vuplex ? "" : "Build/Unity/WebGL.loader.js",
    dataUrl: window.vuplex ? "" : "Build/Unity/WebGL.data.unityweb",
    frameworkUrl: window.vuplex ? "" : "Build/Unity/WebGL.framework.js.unityweb",
    codeUrl: window.vuplex ? "" : "Build/Unity/WebGL.wasm.unityweb",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: window.vuplex ? "" : "Build/Unity/StreamingAssets",
  });

  const { unityDataHandler } = useSignalR();
  const {
    profileModal,
    pastDate
  } = useData();
  const [showLogo, setShowLogo] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );
  const [currid, setcurrid] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [playerState, setPlayerState] = useState(false);
  const [tagsData, setTagsData] = useState<any>(null);
  const [expressionsData, setExpressionsData] = useState<any>(null);
  const [tagsIds, setTagsIds] = useState([]);
  const [expressionIds, setExpressionsIds] = useState([]);
  const [gaugeData, setGaugeData] = useState([]);
  const [isGauge, setIsGauge] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getExpressionData([24], pastDate);
      if (response.status === 200) {
        setGaugeData(response.data);
        setIsGauge(true);
      }
      if (response.status !== 200) {
        setIsGauge(false);
      }
    })();
  }, [unityLoaded, pastDate]);

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  const baseUrl = localStorage.getItem("baseUrl");
  const webglUrl = localStorage.getItem("webglUrl");
  const webglPort = localStorage.getItem("webglPort");

  useEffect(() => {
    if (unityLoaded) {
      (async () => {
        try {
          const response: any = await getAllTagsWithParents();
          const { data } = response;
          const dataToString = JSON.stringify(data);
          sendMessage("DataManager", "SetAllTagsMeta", dataToString);
        } catch (error) {
          console.error('Failed to fetch tags:', error);
        }
      })();
      (async () => {
        try {
          const response: any = await getAllExpressions();
          const { data } = response;
          const dataToString = JSON.stringify(data);
          sendMessage("DataManager", "SetAllExpressionsMeta", dataToString);
        } catch (error) {
          console.error('Failed to fetch expressions:', error);
        }
      })();
    }
  }, [unityLoaded]);

  const handleUnityTags = useCallback((message: any) => {
    const ids = JSON.parse(message);
    setTagsIds(ids);
  }, []);

  const handleUnityExpressions = useCallback((message: any) => {
    const ids = JSON.parse(message);
    setExpressionsIds(ids);
  }, []);

  const useDataFetcher = (ids: any, messageType: any, fetchDataFn: any, unityLoaded: any) => {
    useEffect(() => {
      if (ids.length > 0 && unityLoaded) {
        const fetchData = async () => {
          const response = await fetchDataFn(ids, pastDate);
          if (response.status === 200) {
            sendMessage("DataManager", messageType, JSON.stringify(response.data));
          }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 15000);
        return () => clearInterval(intervalId);
      }
    }, [ids, fetchDataFn, messageType, unityLoaded, pastDate]);
  };

  useDataFetcher(tagsIds, "SetTagsData", getTagData, unityLoaded);
  useDataFetcher(expressionIds, "SetExpressionsData", getExpressionData, unityLoaded);


  const unityReadyHandler = useCallback(() => {
    setUnityLoaded(true);
    setShowLogo(true);
    setTimeout(() => {
      setShowLogo(false);
    }, 5000);
    if (baseUrl) {
      sendMessage("Utilities", "SetBackendUrl", baseUrl);
    }
    if (webglUrl && webglPort) {
      const webglserverurl = { webglUrl: webglUrl, webglPort: webglPort };
      sendMessage(
        "Utilities",
        "SetWebGlServerUrl",
        JSON.stringify(webglserverurl)
      );
    }
  }, [sendMessage]);

  const pauseUnity = useCallback(() => {
    sendMessage("Utilities", "PauseGameFromReact");
  }, [sendMessage]);

  const handleModalLoader = useCallback(() => {
    pauseUnity();
    setModalLoader(false);
  }, [pauseUnity]);

  const resumeUnity = useCallback(() => {
    if (unityLoaded) {
      sendMessage("Utilities", "ResumeGame");
    }
  }, [unityLoaded, sendMessage]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  useEffect(() => {
    if (unityLoaded) {
      document.getElementById("navWrp")?.classList?.add("!static");
    } else {
      document.getElementById("navWrp")?.classList?.remove("!static");
    }
  }, [unityLoaded, sendMessage]);

  const auth = useAuth();
  const logOut = useCallback(() => {
    (async () => {
      auth.signoutRedirect();
      setIsLoggedIn("loggedOut");
    })();
  }, []);

  useEffect(() => {
    return () => {
      if (unityLoaded) {
        pauseUnity();
      }
    };
  }, [unityLoaded]);

  const fetchLatestData = useCallback(() => {
    sendMessage("UpdateBubbles", "UpdateFromSignalR");
  }, [sendMessage]);

  useEffect(
    function () {
      if (unityLoaded) {
        unityDataHandler(fetchLatestData);
      }
    },
    [unityLoaded, fetchLatestData]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        sendMessage("UserDetails", "setUserDetails", JSON.stringify(user));
      }
    },
    [unityLoaded]
  );

  useEffect(() => {
    if (unityLoaded) {
      if (currid !== null)
        sendMessage("Utilities", "MoveUserToLocation", parseInt(currid));
    }
  }, [currid, unityLoaded]);

  useEffect(() => {
    if (unityLoaded) {
      if (profileModal) {
        pauseUnity();
      } else {
        resumeUnity();
      }
    }
  }, [unityLoaded, profileModal, pauseUnity, resumeUnity]);

  const handlePlayerState: any = useCallback((state: boolean) => {
    setPlayerState(state);
  }, []);

  useEffect(
    function () {
      addEventListener("UnityIsReady", unityReadyHandler);
      addEventListener("PlayerState", handlePlayerState);
      addEventListener("makeLogOut", logOut);
      addEventListener("UnityTagsRequest", handleUnityTags);
      addEventListener("UnityExpressionsRequest", handleUnityExpressions);
      addEventListener("unityProgressBar", unityProgressBarHandler);

      return () => {
        removeEventListener("UnityIsReady", unityReadyHandler);
        removeEventListener("PlayerState", handlePlayerState);
        removeEventListener("makeLogOut", logOut);
        removeEventListener("UnityTagsRequest", handleUnityTags);
        removeEventListener("UnityExpressionsRequest", handleUnityExpressions);
        removeEventListener("unityProgressBar", unityProgressBarHandler);
      };
    },
  );

  return (
    <WebGLContext.Provider
      value={{
        resumeUnity,
        pauseUnity,
        handleModalLoader,
        handleCloseModal,
        modalOpen,
        modalLoader,
      }}
    >
      <div id="unity-container" className={classNames('w-full h-[94%] 3xl:h-[94%] 4xl:h-[95%]')}>
        {isGauge && gaugeData.length > 0 &&
          <div className="absolute top-20 right-0 w-max h-max">
            <UnityWidgetContainer data={gaugeData[0]} name='Total Visitors' units='People' />
          </div>
        }
        <SplashScreen
          open={!unityLoaded || showLogo}
          logo={true}
          status={loadingStatus}
          progressStatus={unityLoadingProgress}
        />
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: "100%",
            height: "100%",
            visibility: unityLoaded || showLogo ? "visible" : "hidden",
          }}
          devicePixelRatio={isScaled ? 1.308 : 1}
        />
      </div>
    </WebGLContext.Provider >
  );
};

export default WebGL;