import TextOverflow from "utilities/TextOverflow";
import classNames from "utilities/ClassNames";
import { KpiTabType } from "pages/Dashboard/AllDynamicWidgets/domain/types";



export const KpiTab = ({ data, name }: KpiTabType) => {
  return (
    <>
      {data && (
        <div className="rounded-md bg-dark p-3 flex items-center justify-center">
          <div className="flex flex-col gap-1">
            <p
              className={classNames("text-xl",
                "capitalize font-bold text-center whitespace-nowrap text-green"
              )}
            >
              {name || data.name}
            </p>
            <div className="flex gap-1 justify-center">
              {data.value ? (
                <p
                  className={classNames("lg:text-4xl 2xl:text-5xl",
                    "text-white font-semibold"
                  )}
                >
                  {data?.uom === "%"
                    ? TextOverflow(data?.value.toFixed(2), 7)
                    : TextOverflow(data?.value.toFixed(2), 7)}
                </p>
              ) : (
                <p className="text-white font-semibold text-5xl">
                  {TextOverflow(data?.formattedValue, 7)}
                </p>
              )}
              {data.value && (
                <p className="text-white font-semibold text-lg self-end">
                  {data?.uom}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
