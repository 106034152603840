import Area from "components/charts/Area/Area";
import Bar from "components/charts/Bar/Bar";
import classNames from "utilities/ClassNames";

export default function Chart({ data, name }: any) {
  return (
( data &&   <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="lg:w-[97%] w-full h-full bg-dark rounded-md">
        <div className="w-full lg:h-[80%] 2xl:h-[80%]">
          <Area
            item={data}
            type={data.tagNameIdentifier ? "tag" : "expression"}
            areaType={"regular"}
            renderType="lazyLoading"
            maintainAspectRatio={false}
          />
        </div>
        <div className={classNames("w-[70%]", "mx-auto")}>
          <Bar
            meta={data}
            barType={"small"}
            name={name}
          />
        </div>
      </div>
    </div>)
  );
}
