import { chartConfigurationsProps, configDataProps } from "../domain/types";

export const getDefaultConfigurations = (type: string): chartConfigurationsProps | configDataProps => {
  switch (type) {
    case 'large':
      return {
        k1: { id: '', name: '', value: '', type: '' },
        k2: { id: '', name: '', value: '', type: '' },
        k3: { id: '', name: '', value: '', type: '' },
      };
    case 'kpi':
      return {
        k1: { id: '', name: '', value: '', type: '' },
        k2: { id: '', name: '', value: '', type: '' },
        k3: { id: '', name: '', value: '', type: '' },
        k4: { id: '', name: '', value: '', type: '' },
      };
    case 'area':
      return { id: '', name: '', value: '', type: '' };
    case 'gauge':
    case 'gaugeBar':
      return {
        k1: { id: '', name: '', value: '' },
        k2: { id: '', name: '', value: '' },
        k3: { id: '', name: '', value: '' },
        k4: { id: '', name: '', value: '' },
      };
    case 'column':
      return {
        k1: { id: '', name: '', value: '' },
        k2: { id: '', name: '', value: '' },
        k3: { id: '', name: '', value: '' },
        k4: { id: '', name: '', value: '' },
      };
    case 'oneGaugeBar':
      return { k1: { id: '', name: '', value: '' } };
    default:
      return { k1: { id: '', name: '', value: '' } };
  }
};
