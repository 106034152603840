import React, { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ActionItemProps } from "../domain/types";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

const ActionItem: React.FC<ActionItemProps> = (props) => {
  const { userRole } = useApp();
  const { setSelectedPage, type, actionName, item } = props;
  const [isDisabled, setIsEditDisabled] = useState(false);

  useEffect(() => {
    if (type === 'tickets' && item?.statusId !== 'Open' && actionName !== 'delete') {
      setIsEditDisabled(true);
    }
  }, [item, type]);

  useEffect(() => {
    if (userRole !== 'admin') {
      setIsEditDisabled(true);
    }
  }, [userRole]);

  const handleActionClick = () => {
    props.action(item, actionName);
    if (setSelectedPage) {
      setSelectedPage(`${type}s`);
    }
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger
          className={classNames(
            isDisabled ? "" : "cursor-pointer",
            props.actionName === 'reset' ? "hover:text-[#BE7A3E]" : "",
            props.actionName === 'close' ? "hover:text-[#BE7A3E]" : "",
            props.danger ? "hover:text-[#FF4747] cursor-pointer" : "",
            "disabled:opacity-50 disabled:cursor-default hover:text-green ",
          )}
          onClick={isDisabled ? () => { } : handleActionClick}
          disabled={isDisabled}>
          {props.icon}
        </TooltipTrigger>
        <TooltipContent>
          <p className={classNames(
            props.actionName === 'reset' ? "text-[#BE7A3E]" : "",
            props.actionName === 'close' ? "text-[#BE7A3E]" : "",
            props.danger ? "text-[#FF4747]" : "",
            "text-[#34d399] text-[12px] xl:text-[13px] 2xl:text-xl")}>{props.text}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider >
  );
};

export default ActionItem;
