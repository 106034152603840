import { leapApi } from "utilities/Axios";
import { createContext, useContext, useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import useApp from "./useApp";
import { PinWidgetAPI } from "api/types/apiTypes";

const PinsContext = createContext<any>(null);

export function PinsProvider({ children }: any) {
  const { isLoggedIn } = useApp();
  const [loading, setLoading] = useState(true);
  const [pinLoading, setPinLoading] = useState<boolean>();
  const [allPinsMeta, setAllPinsMeta] = useState<any>(null);
  const [allPinsData, setAllPinsData] = useState<any>(null);
  const [unityPins, setUnityPins] = useState<any>(null);
  const [dashboardPins, setDashboardPins] = useState<any>(null);

  const getAllPinsMeta = async () => {
    const response: PinWidgetAPI = await leapApi.get("/Dashboard/GetPinnedWidgets");
    if (response.status === 200) {
      setAllPinsMeta(response.data);
    }
  };

  useEffect(() => {
    if (isLoggedIn === "loggedIn") {
      getAllPinsMeta();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    async function getPinData() {
      let a = allPinsMeta.length;
      const _pinsWithData: any = [];
      while (a >= 0) {
        if (allPinsMeta[a]?.tagId) {
          const response: any = await getTagData([allPinsMeta[a].tagId]);
          if (response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        if (allPinsMeta[a]?.expressionId) {
          const response: any = await getExpressionData([
            allPinsMeta[a].expressionId,
          ]);
          if (response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        a--;
      }
      setAllPinsData(_pinsWithData);
    }
    if (allPinsMeta && allPinsMeta.length > 0) {
      getPinData();
    } else {
      setAllPinsData([]);
      setUnityPins([]);
      setDashboardPins([]);
    }
  }, [allPinsMeta]);

  const filterPins = (allPins: any) => {
    const dashboardPins = allPins.filter((pin: any) => pin.meta.dashBoardType === 1);
    return dashboardPins.filter((pin: any) => pin.data.length !== 0);
  };

  useEffect(() => {
    if (allPinsData) {
      setUnityPins(
        allPinsData.filter((pin: any) => pin.meta.dashBoardType === 2)
      );
      setDashboardPins(
        filterPins(allPinsData)
      );
    }
    setLoading(false);
  }, [allPinsData]);

  const pin = async (tagId: any, expressionId: any, dashBoardType: any) => {
    setPinLoading(true);
    getAllPinsMeta();
    if (dashBoardType === 2 && unityPins.length < 6) {
      const response: any = await leapApi.post("/Dashboard/PinWidget", {
        tagId: tagId ? tagId : null,
        expressionId: expressionId ? expressionId : null,
        isPinned: true,
        dashBoardType,
      });
      if (response.status === 200) {
        getAllPinsMeta();
      }
    }
    setPinLoading(false);
  };

  const pinTag = async (tagId: any, pinStatus: any) => {
    setPinLoading(true);
    const response: any = await leapApi.post("/Dashboard/PinWidget", {
      tagId: tagId,
      expressionId: null,
      isPinned: pinStatus,
      dashBoardType: 1,
    });
    if (response.status === 200) {
      getAllPinsMeta();
    }
    setPinLoading(false);
  };

  const unPin = async (tagId: any, expressionId: any, dashBoardType: any) => {
    setPinLoading(true);
    const response: any = await leapApi.post("/Dashboard/PinWidget", {
      tagId: tagId ? tagId : null,
      expressionId: expressionId ? expressionId : null,
      isPinned: false,
      dashBoardType,
    });
    if (response.status === 200) {
      getAllPinsMeta();
    }
    setPinLoading(false);
  };

  const unpinAllWidgets = async (type: number) => {
    setPinLoading(true);
    const response: any = await leapApi.get(`/Dashboard/UnpinAllWidget` + `?dashBoardType=${type}`);
    if (response.status === 200 && type === 1) {
      setDashboardPins([]);
    }
    if (response.status === 200 && type === 2) {
      setUnityPins([]);
    }
    setPinLoading(false);
  };

  return (
    <PinsContext.Provider
      value={{
        pin,
        unPin,
        unpinAllWidgets,
        pinTag,
        unityPins,
        dashboardPins,
        loading,
        pinLoading,
      }}
    >
      {children}
    </PinsContext.Provider>
  );
}

export const usePins = () => useContext(PinsContext);

export default PinsContext;
