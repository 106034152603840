import Bar from "components/charts/Bar/Bar";
import Area from "components/charts/Area/Area";
import { MdQueryStats } from "react-icons/md";
import GaugeBox from "./GaugeBox";
import useData from "hooks/useData";

import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";
import useScreen from "hooks/useScreen";

export default function OneGauge({ tab }: any) {
  const { pastDate } = useData();
  const [loading, setLoading] = useState(true);
  const data = tab?.configurations;
  const [allData, setAllData] = useState<any>(null);
  const dataId = data.k1.id;
  const { name } = data.k1;

  useEffect(() => {
    const fetchData = async () => {
      if (data.k1.value !== "") {
        if (data.k1.type !== "expressions") {
          const response: any = await getTagData([dataId], pastDate);
          if (response.status === 200) {
            setAllData(response.data[0]);
          }
        } else {
          const response: any = await getExpressionData([dataId], pastDate);
          if (response.status === 200) {
            setAllData(response.data[0]);
          }
        }
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [tab, pastDate]);

  const [chartType, setChartType] = useState("gauge");

  function changeChart() {
    if (chartType === "gauge") {
      setChartType("area");
    } else {
      setChartType("gauge");
    }
  }

  return (
    <>
      {allData && (
        <div className="flex flex-col w-full h-full justify-center items-center">
          {/* Gauge */}
          <div className="flex flex-wrap relative w-full h-full justify-center items-center bg-dark p-1 rounded-md mb-2">
            <div
              onClick={changeChart}
              className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300 absolute left-0 top-0"
            >
              <MdQueryStats size={20} />
            </div>
            {chartType === "gauge" && (
              <>
                <GaugeBox data={allData} name={name || allData?.name} largest />
                {!allData && (
                  <div className="w-full h-full grid place-content-center">
                    <h1>N/A</h1>
                  </div>
                )}
              </>
            )}
            {/* Area */}
            {allData && chartType === "area" && (
              <div className="flex flex-wrap w-full justify-center items-center h-[80%]">
                <Area
                  item={allData}
                  type={allData.tagNameIdentifier ? "tag" : "expression"}
                  areaType="regular"
                  renderType="lazyLoading"
                  maintainAspectRatio={true}
                />
              </div>
            )}
            {allData && chartType === "area" && (
              <div className="w-[80%] mx-auto">
                <Bar
                  meta={allData}
                  barType="small"
                  name={name}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
