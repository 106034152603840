import Loader from "components/shared/Loader";
import React from "react";
import TextOverflow from "utilities/TextOverflow";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import classNames from "utilities/ClassNames";
import Chart1 from "pages/NewDashboard/Chart1";
import { cn } from "@/lib/utils";
import useData from "hooks/useData";

const Large = ({ tab }: any) => {
  const { pastDate } = useData();
  const data = tab.configurations;
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [current, setCurrent] = useState(0);
  const namesCollection = Object.entries(data).map((singleItem: any) => singleItem[1]?.name);

  useEffect(() => {
    const fetchData = async () => {
      const dataArray = Object.entries(data).map((item) => item[1]);
      const promises = dataArray.map(async (single: any) => {
        if (single.id!=='') {
          const response = single?.type === 'tags' ? await getTagData([single?.id]) : await getExpressionData([single?.id]);
          if (response.status === 200) {
            return response.data[0];
          }
        }
        return null;
      });
      const results = await Promise.all(promises);
      setProcessedData(results);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [data, pastDate]);

  

  return (
    <>
      {!data && (
        <div className="w-full h-full grid place-content-center">
          <Loader />
        </div>
      )}
      <div className="w-full h-full flex p-1">
        <div className={classNames("w-[27%] h-full", "flex flex-col gap-3")}>
          {/* KPI 1 */}
          {processedData && processedData.map((item: any, index: any) => (
            item && (
              <div
                key={index}
                className={cn("p-3 lg:h-[31%] 2xl:h-[31%]", "rounded-md bg-dark flex items-center justify-center cursor-pointer")}
                onClick={() => setCurrent(index)}
              >
                <div className="flex flex-col gap-1">
                  <p
                    className={classNames("text-xl",
                      "capitalize font-bold text-center whitespace-nowrap text-green"
                    )}
                  >
                    {namesCollection[index] || item.name}
                  </p>
                  <div className="flex gap-1 justify-center">
                    {item.value ? (
                      <p className={cn("text-white font-semibold", "lg:text-3xl 2xl:text-4xl")}>
                        {item?.uom === "%"
                          ? TextOverflow(item.value.toFixed(2), 7)
                          : TextOverflow(item.value.toFixed(2), 7)}
                      </p>
                    ) : (
                      <p className="text-white font-semibold lg:text-3xl 2xl:text-5xl">
                        {TextOverflow(item.formattedValue, 7)}
                      </p>
                    )}
                    {item.value && (
                      <p className="text-white font-semibold text-lg self-end">
                        {item?.uom}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )
          ))}
        </div>

        <div className={classNames("w-[73.5%] h-full", "flex justify-center items-center")}>
          {processedData.map((item: any, index: any) => (
            current === index && (
              <div key={index} className="w-full flex h-full justify-center items-center">
                <Chart1 data={item} name={namesCollection[index]} />
              </div>
            )
          ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(Large);
