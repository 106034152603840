import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useCallback, useEffect, useRef, useState } from "react";
import theme from "./config/theme";

import { Doughnut } from "react-chartjs-2";
import classNames from "utilities/ClassNames";
import calculateData from "./utils/calculateData";
import colorCodes from "./config/colorCodes";
import drawHiddenSplits from "./utils/drawSplits/drawHiddenSplits";
import drawSplits from "./utils/drawSplits/drawSplits";
import genThresholdRange from "./utils/genThresholdRange";
import genGaugeData from "./utils/gaugeData";
import getOptions from "./utils/getOptions";
import { GaugeProps, GaugeOptions } from "./domain/types";
import { gaugeConfig } from "./config/configurations";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const genFontSize = (value: any) => {
  if (value.length > 9) {
    return '3xl:text-lg 2xl:text-md text-[11px] lg-xl:text-lg xl:text-[13px] 1xl:text-[15px]';
  }
  if (value.length >= 5) {
    return 'text-[11px] lg-xl:text-lg xl:text-[13px] 1xl:text-[15px] 1xl:top-[55%] 2xl:text-xl 3xl:text-2xl';
  }
  if (value.length < 5) {
    return 'text-[11px] lg-xl:text-lg xl:text-[13px] 1xl:text-[15px] 1xl:top-[55%] 2xl:text-xl 3xl:text-2xl';
  }
  return '';
};

const Gauge: React.FC<GaugeProps> = ({ data, name, gaugeType }) => {
  const outOfThresholdRange = genThresholdRange(colorCodes);
  const [valueDataSet, setValueDataSet] = useState(outOfThresholdRange.value);
  const [valueBG, setValueBG] = useState(outOfThresholdRange.BG);

  const [operatingDataSet, setOperatingDataSet] = useState<number[]>([]);
  const [operatingBG, setOperatingBG] = useState<string[]>([]);

  const [total, setTotal] = useState(0);
  const [disabled, setDisable] = useState(false);
  const [inRange, setInRange] = useState(true);

  const { minValue, maxValue, formattedValue, minRange, maxRange } = data;
  const [gradient, setGradient] = useState<string | null>(null);
  const chartRef = useRef<any>(null);
  const { titleSize, doughtnutContainerClasses, labelClasses, valueClasses } = gaugeConfig[gaugeType];

  useEffect(() => {
    if (data) {
      const calculatedData = calculateData(data, 100);

      setTotal(calculatedData.totalValue);
      setInRange(calculatedData.inRange);

      setOperatingDataSet(calculatedData.rangeSet.data);
      setOperatingBG(calculatedData.rangeSet.bg);

      if (!calculatedData.defaultDataSet) {
        setValueBG(calculatedData.valueSet.bg);
        setValueDataSet(calculatedData.valueSet.data);
      }

      setDisable(calculatedData.disable);
    }
  }, [data, drawSplits]);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart?.ctx;
      const gradientSegment = ctx.createLinearGradient(0, 0, 250, 0);
      const endBGData = valueDataSet[0] / total;

      const gradient = { start: theme.primary, end: theme.secondary };

      if (!inRange) {
        gradient.start = colorCodes.orange;
        gradient.end = colorCodes.red;
      }
      gradientSegment.addColorStop(0, gradient.start);
      gradientSegment.addColorStop(endBGData, gradient.end);

      setGradient(gradientSegment);
    }
  }, [valueBG, valueDataSet]);

  let bgGradient: (CanvasGradient | string | null)[] = [gradient];
  let borderBg: (CanvasGradient | string | null)[] = [gradient];
  if (valueBG.length === 2) {
    bgGradient = [gradient, valueBG[1]];
    borderBg = [gradient, "transparent"];
  } else if (valueBG.length === 1 && valueBG[0] === colorCodes.white) {
    bgGradient = valueBG;
    borderBg = ["transparent"];
  }

  const gaugeNeedle = useCallback(() => {
    return {
      id: `gaugeChart_${data.id}`,
      beforeDraw(chart: ChartJS<"doughnut", number[], unknown>, args: { cancelable: boolean }, options: GaugeOptions) {
        drawHiddenSplits({
          chart,
          args,
          options,
          values: {
            formattedValue,
            minRange,
            maxRange,
            minValue,
            maxValue,
            total,
          }
        });
      },
      afterDraw(chart: ChartJS<"doughnut", number[], unknown>, args: any, options: GaugeOptions) {
        drawSplits({
          chart,
          args,
          options,
          values: {
            formattedValue,
            minRange,
            maxRange,
            minValue,
            maxValue,
            total,
          },
          gaugeType
        });
      },
    };
  }, [total]);

  const gaugeData = genGaugeData({ valueDataSet, operatingBG, operatingDataSet, bgGradient, borderBg });
  const fontSize = 12;
  const options = getOptions({ fontSize, formattedValue });

  return (
    <div className={classNames(disabled ? "opacity-50" : "", "max-h-full max-w-full h-full w-full")}>
      <div className="h-full">
        {total ? (
          operatingDataSet && (
            <div className="w-full h-full overflow-hidden relative flex flex-col justify-center items-center z-0">
              <div className={classNames(doughtnutContainerClasses, "z-50")}>
                <Doughnut
                  ref={chartRef}
                  id={`gaugeChart_${data.id}`}
                  key={`gaugeChart_${data.name}_${data.id}`}
                  data={gaugeData}
                  plugins={[gaugeNeedle()]}
                  options={options}
                  className={"w-full"}
                />
              </div>
              <div className={classNames(labelClasses, "")}>
                <h4 className={classNames(gaugeType === 'regular' ? genFontSize(formattedValue) : "", valueClasses, "text-center opacity-80 absolute left-0 w-full")}>
                  <span className={classNames("block mx-auto")}>
                    {data.uom === "%"
                      ? `${Math.trunc(parseInt(formattedValue))}%` || "-"
                      : formattedValue || "-"}
                  </span>
                </h4>
                <h4 className={classNames(titleSize, "text-center text-white font-bold")}>
                  {name ? name : data.name}
                </h4>
              </div>
            </div>
          )
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center gap-5">
            <p>Range not available</p>
            <h4
              className={classNames(
                "text-center font-weight-bold"
              )}
            >
              {formattedValue || "-"}
            </h4>
            <h4
              className={classNames(
                "text-center color-primary"
              )}
            >
              {name || data.name}
            </h4>
          </div>
        )}
      </div >
    </div >
  );
};

export default React.memo(Gauge);
