import React, { useEffect, useState, useRef } from "react";
import CustomizedTimeline from "./Timeline";
import { Button } from "@/components/ui/button";
import { getSystemLogs } from "api/dashboard";
import useApp from "hooks/useApp";
import useScreen from "hooks/useScreen";

interface ILogs {
  action: string;
  at: string;
  by: string;
  desc: string;
  detail: string;
  module: string;
}

const Logs: React.FunctionComponent = () => {
  const [data, setdata] = useState<ILogs[]>([]);
  const [showpopup, setshowpopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const isScaled = false;
  const { userRole } = useApp();

  const getData = async () => {
    const response: any = await getSystemLogs({});
    if (response.status === 200 && response.data) {
      const tempData = response.data;
      tempData.map((item: any) => {
        const datestring = item.at;
        const date = new Date(datestring);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        item.at = formattedDate;
      });
      setdata(tempData);
    }
  };

  const handleDownload = () => {

    const jsonString = JSON.stringify(data, null, 2);
    const b64 = window.btoa(jsonString) + "ajx";
    const blob = new Blob([b64], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "logs.ph";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const handleDownloadAsText = () => {

    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "logs.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const ToggleDownloadPopup = () => {
    setshowpopup(() => !showpopup);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;

        if (content.endsWith("ajx")) {
          const json = window.atob(content.slice(0, -3));
          const data = JSON.parse(json);
          setdata(data);
        }
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsAdmin(userRole === 'admin');
  }, [userRole]);

  const fileInputRef: any = useRef(null);
  const handleUpload = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const buttonsFont = isScaled ? { fontSize: '25px' } : {};

  return (
    <>
      <div className="row" style={{ marginTop: '40px' }}>
        <div style={{ display: 'flex', marginTop: '40px', marginLeft: '10px' }}>
          <Button
            className="btn"
            style={{ ...buttonsFont, marginLeft: "0px", marginRight: "10px" }}
            onClick={ToggleDownloadPopup}
          >
            {" "}
            Download
          </Button>
          {showpopup &&
            <div style={{ background: 'black', border: '2px darkcyan solid', borderRadius: '5px', width: '120px', height: '60px', position: 'absolute', marginLeft: '0px', marginTop: '45px', zIndex: 10000 }}>
              <div style={{ fontSize: '14px', padding: '5px', color: 'darkcyan', cursor: 'pointer' }} onClick={() => { handleDownloadAsText(); ToggleDownloadPopup(); }}>As .txt file</div>
              <div style={{ fontSize: '15px', padding: '0px', marginLeft: '40px', color: 'darkcyan', cursor: 'pointer' }} onClick={() => { handleDownload(); ToggleDownloadPopup(); }}>Raw</div>
            </div>
          }
          {isAdmin &&
            <Button
              className="btn"
              style={{ ...buttonsFont, marginLeft: "0px", marginRight: "10px" }}
              onClick={handleUpload}
            >
              {" "}
              Upload
            </Button>
          }
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".ph"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <h1 style={{ textAlign: "center", marginTop: '-45px', fontSize: '35px' }}>
        LOGS
      </h1>
      <div style={{ marginTop: '50px', marginLeft: '10px' }}>
        <CustomizedTimeline logs={data} />
      </div>
    </>
  );
};

export default Logs;
