export default {
  regular: {
    containerClasses: 'lg:-mt-2 2xl:mt-0',
    titleContainerClasses: '',
    titleClasses: 'text-xl leading-3 text-green',
    barDisplayClasses: 'p-1 lg:mt-0 xl:mt-1',
    barDisplayHeight: 'h-2.5',
    scalesSize: 'xl:h-2 lg:h-1.5 w-2',
    scalesValuesClasses: '-right-5 lg:text-sm xl:text-md',
    unitSize: 'text-lg',
    valueSize: 'text-lg',
    barScalesAmount: 85,
  },
  medium: {
    containerClasses: '',
    titleContainerClasses: 'mb-1.5',
    titleClasses: 'text-green text-lg 2xl:text-xl 3xl:text-2xl flex',
    barDisplayClasses: 'p-1 mt-0.5',
    barDisplayHeight: 'h-2',
    scalesSize: 'h-1.5 w-1.5',
    scalesValuesClasses: 'text-lg 2xl:text-xl -right-4',
    unitSize: 'text-[16px] text-md  2xl:text-lg',
    valueSize: 'text-md text-lg 2xl:text-xl',
    barScalesAmount: 85,
  },
  small: {
    containerClasses: 'xl:-mt-1 2xl:mt-0',
    titleContainerClasses: '',
    titleClasses: 'text-green lg:text-sm 2xl:text-lg flex',
    barDisplayClasses: 'lg:p-1',
    barDisplayHeight: 'lg:h-1 2xl:h-2',
    scalesSize: 'lg:h-1.5 w-1.5',
    scalesValuesClasses: '-right-4 lg:text-xs 2xl:text-sm',
    unitSize: 'lg:text-sm 2xl:text-lg',
    valueSize: 'lg:text-sm 2xl:text-lg',
    barScalesAmount: 85,
  },
  smallest: {
    containerClasses: 'truncate items-center',
    titleContainerClasses: '',
    titleClasses: 'text-green lg:text-[11px] lg:mt-0.5 xl:text-md 2xl:text-lg lg:truncate',
    barDisplayClasses: 'p-1 lg:p-0.5 xl:p-1',
    barDisplayHeight: 'h-1.5 lg:h-1 2xl:h-1.5 4xl:h-2',
    scalesSize: 'h-1 4xl:h-2 w-1 4xl:w-2',
    scalesValuesClasses: 'lg:text-[12px] 3xl:text-[15px] 4xl:text-sm',
    unitSize: 'lg:text-[12px] xl:text-md 2xl:text-md 4xl:text-lg',
    valueSize: 'lg:text-[12px] xl:text-md 2xl:text-lg 4xl:text-lg',
    barScalesAmount: 45,
  },
  pin: {
    containerClasses: 'truncate',
    titleContainerClasses: '',
    titleClasses: 'text-green xl:text-sm 2xl:text-[18px] flex',
    barDisplayClasses: '',
    barDisplayHeight: 'lg:h-3 2xl:h-3.5',
    scalesSize: 'h-1 2xl:h-1.5 w-full',
    scalesValuesClasses: 'text-[12px] 3xl:text-[15px]',
    unitSize: 'xl:text-sm 2xl:text-lg',
    valueSize: 'xl:text-sm 2xl:text-lg',
    barScalesAmount: 50,
  },
  unityPin: {
    containerClasses: 'truncate',
    titleContainerClasses: '',
    titleClasses: 'text-green xl:text-sm 2xl:text-[16px] flex',
    barDisplayClasses: '',
    barDisplayHeight: 'xl:h-2.5 2xl:h-3.5',
    scalesSize: 'h-1 2xl:h-1.5 w-1',
    scalesValuesClasses: '-right-4 xl:text-sm',
    unitSize: 'xl:text-xs 2xl:text-md',
    valueSize: 'xl:text-xs 2xl:text-md',
    barScalesAmount: 85,
  },

  regularScaled: {
    containerClasses: '',
    titleContainerClasses: '',
    titleClasses: 'text-xl leading-3 text-green',
    barDisplayClasses: 'p-1 mt-1',
    barDisplayHeight: 'h-2.5',
    scalesSize: 'h-2 w-2',
    scalesValuesClasses: '-right-7',
    unitSize: 'text-[20px]',
    valueSize: 'text-lg',
    barScalesAmount: 85,
  },
  mediumScaled: {
    containerClasses: '',
    titleContainerClasses: '',
    titleClasses: 'text-green text-md flex',
    barDisplayClasses: 'p-1',
    barDisplayHeight: 'h-2',
    scalesSize: 'h-1 w-1',
    scalesValuesClasses: '-right-5',
    unitSize: 'text-[16px]',
    valueSize: 'text-md',
    barScalesAmount: 85,
  },
  smallScaled: {
    containerClasses: '',
    titleContainerClasses: '',
    titleClasses: 'text-green text-md flex',
    barDisplayClasses: 'p-1',
    barDisplayHeight: 'h-1.5',
    scalesSize: 'h-1 w-1',
    scalesValuesClasses: '-right-5 text-sm',
    unitSize: 'text-[14px]',
    valueSize: 'text-sm',
    barScalesAmount: 85,
  },
  smallestScaled: {
    containerClasses: 'truncate',
    titleContainerClasses: '',
    titleClasses: 'text-green text-sm flex',
    barDisplayClasses: 'p-0.5',
    barDisplayHeight: 'h-1',
    scalesSize: 'h-0.5 w-0.5',
    scalesValuesClasses: '-right-4 text-xs',
    unitSize: 'text-xs',
    valueSize: 'text-xs',
    barScalesAmount: 85,
  },
  pinScaled: {
    containerClasses: 'truncate',
    titleContainerClasses: '',
    titleClasses: 'text-green text-sm flex',
    barDisplayClasses: 'p-0.5',
    barDisplayHeight: 'h-2.5',
    scalesSize: 'h-1 w-1',
    scalesValuesClasses: '-right-4 text-sm',
    unitSize: 'text-sm',
    valueSize: 'text-sm',
    barScalesAmount: 85,
  },

};
