import { createContext } from "react";

const WebGLContext = createContext<any>({
  resumeUnity: () => { },
  pauseUnity: () => { },
  handleModalLoader: () => { },
  handleCloseModal: () => { },
  modalOpen: false,
  modalLoader: false,
});

export default WebGLContext;
