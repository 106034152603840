import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { getEquipmentsByUnitId } from "api/equipments";
import { useEffect, useState, useMemo } from "react";
import { FiX } from "react-icons/fi";
import TableManager from "components/TableManager/TableManager";
import Debounce from "utilities/Debounce";
import DataTable from "components/DataTable/DataTable";
import { getAllTags, mapEquipmentTag } from "api/tags";
import Loader from "components/shared/Loader";

const MappingModal = ({ listing, setSelectedTag, refresh }: any) => {
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);
  const [mappedTags, setMappedTags] = useState<any>({
    added: [],
    removed: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const { selectedEquipment } = listing;

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }
    return items;
  }, [data, debouncedKeyword]);

  const onClickHandler = () => {
  };

  useEffect(() => {
    const fetchTags = async () => {
      const response = await getAllTags();
      if (response.status === 200) {
        setData(response.data);
        setIsLoading(false);
      }
    };
    fetchTags();
  }, []);

  const submitAction = async () => {
    setIsLoading(true);
    const { added, removed } = mappedTags;
    const addedTags = added.map((tag: number) => ({
      equipmentId: selectedEquipment.id,
      tagId: tag,
      isMapped: true,
    }));
    const removedTags = removed.map((tag: number) => ({
      equipmentId: selectedEquipment.id,
      tagId: tag,
      isMapped: false,
    }));
    const response = await mapEquipmentTag([...addedTags, ...removedTags]);
    setIsLoading(false);
    if (response.status === 200) {
      refresh();
      setSelectedTag({ action: "", value: null });
    }
  };

  return (
    <div className="z-10 h-full w-full">
      <BorderGradient className="w-full h-max">
        <div className="w-full p-3 bg-dark rounded-md h-[65vh] overflow-hidden">
          <div className="flex flex-wrap justify-between pb-5">
            <h1>Map Tag to
              <span className="text-green font-bold"> {selectedEquipment.name}</span>
            </h1>
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() =>
                setSelectedTag({ action: "", value: null })
              }
            >
              <FiX size={20} />
            </button>
          </div>
          <TableManager setKeyword={setKeyword} type="mapping" btnAction={submitAction} searchRightSide={false} />
          {data.length > 0 && !isLoading &&
            <DataTable
              type="tags"
              hasPins={false}
              special={true}
              hasActions={false}
              rows={rows}
              setMappedTags={setMappedTags}
              mappedTags={mappedTags}
              onClickHandler={onClickHandler}
              onActionClickHandler={onClickHandler}
              setSelectedPage={setSelectedTag}
              selectedPage={selectedEquipment}
            />
          }
          {isLoading &&
            <div className="w-full h-[80%] flex justify-center items-center">
              <span className="-mt-4"><Loader /></span>
            </div>
          }
        </div>
      </BorderGradient>
    </div>
  );
};

export default MappingModal;