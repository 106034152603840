/* eslint-disable @typescript-eslint/no-unused-expressions */
import { leapApi as api } from "utilities/Axios";

function subtractHours(date: any) {
	date.setHours(date.getHours() - 720);
	return date;
}

export const getAllTags = () => {
	return api.get("/TagMeta/GetAllTags");
};

export const getTagData = (
	ids: any,
	dateTime?: string,
) => {
	let url = "Dashboard/GetTagData";
	dateTime ? (url += `?dateTime=${dateTime}`) : null;
	return api.post(url, ids);
};

export const mapEquipmentTag = (tags: any) => {
	return api.post("/TagMeta/MapEquipmentTag", tags);
};

export const getAllTagsWithParents = () => {
	return api.get("/TagMeta/GetAllTagsWithParent");
};

export const createTag = (tag: any) => {
	return api.post("/TagMeta/CreateTag", tag);
};

export const updateTag = (tag: any) => {
	return api.post("/TagMeta/UpdateTag", tag);
};

export const resetTag = (id: any) => {
	return api.get(`/TagMeta/ResetTagRange?id=${id}`);
};

export const uploadTag = (data: any, signal: any) => {
	return api.post("TagData/Upload", data, { signal: signal });
};

export const deleteTag = (id: any) => {
	return api.get(`/TagMeta/DeleteTag?id=${id}`);
};

export const getTrainsByPlantId = (id: any) => {
	return api.get(`/TagMeta/GetTrainsByPlantId?id=${id}`);
};

export const disableTag = (tagId: number, status: any) => {
	return api.post(`/TagMeta/SetTagDisabled?tagId=${tagId}&isDisabled=${status}`);
};

export const getTagsByEquipmentsId = (equipmentId: any) => {
	return api.get(`/TagMeta/GetTagsByEquipmentsId?id=${equipmentId}`);
};

export const downloadTag = (id: any) => {
	return api.get(`/TagData/DownloadTagData?id=${id}`);
};

export const clearTag = (id: any) => {
	return api.get(`/TagData/ClearTagData?id=${id}`);
};
