import * as React from "react";
import Typography from "@mui/material/Typography";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LogDetails from "./LogDetails";
import { OutlinedInput } from "@mui/material";
import { fontSize, styled } from "@mui/system";
import { getSystemLogs } from "api/dashboard";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

interface ILogs {
  action: string;
  at: string;
  by: string;
  desc: string;
  detail: string;
  module: string;
}

const Actions = ["Information", "Created", "Viewed", "Modified", "Deleted"];
const Modules = [
  "System",
  "User",
  "Ticket",
  "Alarm",
  "Tags",
  "Expression",
  "Train",
  "Unit",
  "Equipment",
  "Bubble",
  "Note",
];

const StyledOutlinedInput = styled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    border: '1px solid rgb(22,245,211)',
    '&:hover': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: 'none',
    },
  },
});

export default function CustomizedTimeline(props: any) {
  const logdata = props.logs;
  const [data, setdata] = useState<any>(null);
  const [Action, setAction] = React.useState<any>(null);
  const [ActionLabel, setActionLabel] = React.useState<any>("Action");
  const [Module, setModule] = React.useState<any>(null);
  const [ModuleLabel, setModuleLabel] = React.useState<any>("Module");
  const [DisplayedLogDetails, setDisplayedLogDetails] = useState<any>(null);
  const [showData, setshowData] = useState(false);
  const durType = 3;
  const [DaystoFilter, setDaystoFilter] = useState<any>(null);
  const { isScaled } = useApp();

  const handleActionChange = (event: any) => {
    setAction(event.target.value);
    setActionLabel(null);
    setDisplayedLogDetails(null);
  };

  const handleModuleChange = (event: any) => {
    setModule(event.target.value);
    // setModuleLabel(Modules[event.target.value]);
    setModuleLabel(null);
    setDisplayedLogDetails(null);
  };

  const handleClear = () => {
    setModule(null);
    setAction(null);
    setActionLabel("Action");
    setModuleLabel("Module");
    setDisplayedLogDetails(null);
  };
  const onDaysChange = (event: any) => {
    setDaystoFilter(event.target.value);
  };

  useEffect(() => {
    if (logdata) setdata(logdata);
  }, []);

  const getData = async ({ action, module }: any) => {
    let response: any = null;
    if (DaystoFilter) {
      response = await getSystemLogs({
        action: action,
        module: module,
        count: DaystoFilter,
        durType: durType
      });
    }
    else {
      response = await getSystemLogs({
        action: action,
        module: module,
      });
    }
    if (response && response.status === 200 && response.data) {
      const tempData = response.data;
      tempData.map((item: any) => {
        const datestring = item.at;
        const date = new Date(datestring);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        item.at = formattedDate;
      });
      setdata(tempData);
    } else {
      setdata(null);
    }
  };

  useEffect(() => {
    getData({ action: Action, module: Module });
  }, [Action, Module, DaystoFilter]);

  const iconsColor = { color: "white" };

  const iconsSizes = isScaled ? { width: "35px", height: "35px" } : {};

  const fontStyles = isScaled ? { fontSize: '20px' } : {};

  const inputLabelStyles = isScaled ? { marginTop: "-8px", color: "white", fontSize: "20px" } : { marginTop: "-6px", color: "white" };

  return (
    <>
      <div
        style={{ display: 'flex', marginBottom: '-80px' }}
      >
        <div className={classNames(isScaled ? "h-[900px]" : "h-[710px]", "gradient w-2/3 mr-2.5 p-0.5 rounded-lg")}>
          <div
            style={{
              display: "flex",
              padding: '10px',
              borderRadius: '7px',
            }}
            className="bg-black h-full"
          >
            <div
              className={isScaled && "text-xl"}
              style={{ marginRight: '50px', height: 'auto' }}
            >
              <div className="row" style={{ display: 'flex' }}>
                <div style={{ color: 'white' }}>Filter by Day: </div>
                <input style={{ background: 'none', border: 'none', borderBottom: '2px rgb(22,245,211) solid', maxWidth: '50px', textAlign: 'center' }} placeholder="10" onChange={onDaysChange}></input>
                <div style={{ color: 'white' }}>days</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel
                    id="action-select-label"
                    style={inputLabelStyles}
                  >
                    {ModuleLabel}
                  </InputLabel>
                  <Select
                    labelId="action-select-label"
                    id="action-select"
                    className="custom-select-style"
                    value={Module}
                    // label="Action"
                    onChange={handleModuleChange}
                    style={{ height: '40px', color: 'white' }}
                    input={<StyledOutlinedInput />}
                  >
                    {Modules.map((module, index) => (
                      <MenuItem key={index} value={index}>{module}</MenuItem>
                    ))}
                    {/* Add your MenuItems here */}
                  </Select>
                </FormControl>
                <FormControl style={{ minWidth: "120px", margin: '0 10px' }}>
                  <InputLabel
                    id="action-select-label"
                    style={inputLabelStyles}
                  >
                    {ActionLabel}
                  </InputLabel>
                  <Select
                    labelId="action-select-label"
                    id="action-select"
                    className="custom-select-style"
                    value={Action}
                    // label="Action"
                    onChange={handleActionChange}
                    style={{ height: '40px', color: 'white' }}
                    input={<StyledOutlinedInput />}
                  >
                    {Actions.map((action, index) => (
                      <MenuItem key={index} value={index}>{action}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  className={
                    classNames(isScaled ? 'text-2xl mt-2' : "mt-[2.5]", "underline text-[rgb(22,245,211)] cursor-pointer")
                  }
                  onClick={handleClear}
                >
                  Clear
                </div>
              </div>
            </div>
            <Timeline
              style={{
                height: "100%",
                width: '1000px',
                overflowY: "scroll",
              }}
            >
              {data &&
                data.map((item: any, index: any) => (
                  <TimelineItem key={index} style={{ flexShrink: 0, background: item === DisplayedLogDetails ? 'rgb(22, 245, 211,0.6)' : '', borderRadius: '5px', marginLeft: '-40px' }}
                    className="hover:opacity-80 cursor-pointer"
                    onClick={() => {
                      setDisplayedLogDetails(item);
                      setshowData(true);

                    }}>
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      align="left"
                      variant="body2"
                      color="white"
                      style={{ minWidth: '200px', marginLeft: '-20px' }}
                    >
                      <div className={isScaled && 'text-lg'}>{item.at}</div>
                      <div className={isScaled && 'text-lg'}>{item.module}</div>
                      <div className={isScaled && 'text-lg'}>{item.by}</div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      {item.action === "Viewed" && (
                        <TimelineDot style={{ backgroundColor: "grey" }}>
                          <RemoveRedEyeOutlinedIcon style={{ ...iconsColor, ...iconsSizes }} />
                        </TimelineDot>
                      )}
                      {item.action === "Modified" && (
                        <TimelineDot style={{ backgroundColor: "#09289A" }}>
                          <UpdateIcon style={{ ...iconsColor, ...iconsSizes }} />
                        </TimelineDot>
                      )}
                      {item.action === "Information" && (
                        <TimelineDot style={{ backgroundColor: "#08AEB2" }}>
                          <PriorityHighOutlinedIcon style={{ ...iconsColor, ...iconsSizes }} />
                        </TimelineDot>
                      )}
                      {item.action === "Created" && (
                        <TimelineDot style={{ backgroundColor: "#099A52" }}>
                          <AddIcon style={{ ...iconsColor, ...iconsSizes }} />
                        </TimelineDot>
                      )}
                      {item.action === "Deleted" && (
                        <TimelineDot style={{ backgroundColor: "#9A0909" }}>
                          <DeleteIcon style={{ ...iconsColor, ...iconsSizes }} />
                        </TimelineDot>
                      )}
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2, minWidth: '520px' }}>
                      <Typography style={fontStyles}>{item.desc}</Typography>
                      {/* <ReadMore Text={item.detail} /> */}
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </div>
        </div>
        {showData && DisplayedLogDetails && (
          <div className={classNames(isScaled ? "h-[900px] text-xl" : "h-[710px]", "w-1/3 gradient rounded-lg p-0.5")}>
            <div
              style={{
                borderRadius: "7px",
              }}
              className="bg-black h-full"
            >
              <LogDetails LogData={DisplayedLogDetails}></LogDetails>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
