export const gaugeConfig = {
  regular: {
    titleSize: 'text-md 2xl:text-xl -mt-2 2xl:-mt-4',
    valueClasses: 'top-[50%] lg:top-[55%] lg-xl:top-[58%] xl:top-[52%] 1xl:top-[55%] 2xl:top-[62%]',
    labelClasses: '',
    doughtnutContainerClasses: 'w-full h-[95%] lg-xl:h-full -mt-4',
    splitsWidth: 1.5,
  },
  large: {
    titleSize: 'text-2xl lg-xl:text-3xl lg-xl:-mt-4 2xl:-mt-8',
    valueClasses: 'text-2xl lg-xl:text-2xl xl:top-[56%] 2xl:top-[60%] top-[60%] lg-xl:top-[65%] 2xl:text-3xl 2xl:top-[63%] 3xl:text-4xl',
    labelClasses: '',
    doughtnutContainerClasses: 'h-[85%] lg-xl:h-full lg-xl:-mt-8 lg:w-full',
    splitsWidth: 3,
  },
  wide: {
    titleSize: 'text-xl -mt-3.5 xl:-mt-2',
    valueClasses: 'top-[60%] text-sm lg-xl:top-[65%] xl:top-[60%] lg-xl:text-md xl:text-lg 2xl:text-2xl 2xl:top-[65%]',
    labelClasses: '',
    doughtnutContainerClasses: 'w-[65%] xl:w-[65%] xl-2xl:w-[70%] h-[100%] mx-auto -mt-4 2xl:-mt-6',
    splitsWidth: 2,
  },
  unity: {
    titleSize: 'text-lg -mt-2',
    valueClasses: 'top-[60%] left-[0%]',
    labelClasses: '',
    doughtnutContainerClasses: 'w-[70%] h-full -mt-7',
    splitsWidth: 1,
  },
};