import {
  deleteExpression as apiDeleteExpression,
  createExpression,
  getAllExpressions,
  updateExpression,
} from "api/expression";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import { filterArray } from "utilities/FilterArray";
import { parseFilters } from "utilities/ParseFilters";
import Debounce from "utilities/Debounce";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import classNames from "utilities/ClassNames";
import useScreen from "hooks/useScreen";
import DataTable from "components/DataTable/DataTable";
import { Item } from "types/common";
import TableManager from "components/TableManager/TableManager";
import PopoverContainer from "components/Popover/PopoverContainer";
import Modal from "components/modal/Modal";
import { ActionName } from "types/common";

export default function ExpressionContainer() {
  const isScaled = false;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);

  const [trainsFilters, setTrainsFilters] = useState<any>([]);
  const [trainsOptions, setTrainsOptions] = useState<any>();
  const [unitsFilters, setUnitsFilters] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>();
  const [expression, setExpression] = useState<any>({
    action: "",
    value: null,
  });

  // table
  const ITEMS_PER_PAGE = 50;
  const [totalPages, setTotalPages] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllExpressions();
    if (response.status === 200) {
      setRawData(response.data);
      //trains
      const trains = response.data.map((item: any) => item.trainName);
      setTrainsOptions(parseFilters(trains));
      //units
      const units = response.data.map((item: any) => item.unitName);
      setUnitsOptions(parseFilters(units));
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAllExpressions();
    if (response.status === 200) {
      setRawData(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length) {
      if (unitsFilters && unitsFilters?.length) {
        const result = filterArray(unitsFilters, rawData, "unitName");
        if (result.length > 0) setData(result);
      } else if (trainsFilters && trainsFilters?.length) {
        const result = filterArray(trainsFilters, rawData, "trainName");
        if (result.length > 0) setData(result);
      } else {
        setData(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (unitsFilters && unitsFilters?.length) {
      const result = filterArray(unitsFilters, rawData, "unitName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [unitsFilters]);

  useEffect(() => {
    if (trainsFilters && trainsFilters?.length) {
      const result = filterArray(trainsFilters, rawData, "trainName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [trainsFilters]);

  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }

    if (items) {
      setTotalPages(() => {
        const array = [];
        for (let i = 1; i <= Math.ceil(items.length / ITEMS_PER_PAGE); i++) {
          array.push(i);
        }
        return array;
      });

      return items?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [data, debouncedKeyword, currentPage]);

  const handleClick = (itemValue: Item, itemAction: ActionName) => {
    setExpression({
      action: itemAction,
      value: itemValue
    });
  };

  const handleManagerBtnClick = () => {
    setExpression({ action: "new", value: null });
  };

  const filterItems = [
    {
      title: 'Trains',
      filters: trainsFilters,
      options: trainsOptions,
      setFilters: setTrainsFilters,
    },
    {
      title: 'Units',
      filters: unitsFilters,
      options: unitsOptions,
      setFilters: setUnitsFilters,
    }
  ];

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Expressions, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && data && (
        <div
          className={classNames(
            isScaled ? "text-2xl min-h-[135vh]" : "text-base min-h-[85vh]",
            "pl-3 pr-3 pt-3 pb-3 flex flex-wrap flex-col justify-start"
          )}
        >
          <div className="mb-3">
            <TableManager setKeyword={setKeyword} btnAction={handleManagerBtnClick}>
              <PopoverContainer items={filterItems} />
            </TableManager>
          </div>
          <div className="h-[78vh] w-full">
            <BorderGradient className=" overflow-hidden h-full">
              <DataTable type="expressions" hasActions={true} hasPins={false} rows={rows} onClickHandler={handleClick} onActionClickHandler={handleClick} />
            </BorderGradient>
          </div>
          <Modal page='expressions' selectedTag={expression} setSelectedTag={setExpression} isLoading={loading} refresh={refreshData} />
        </div>
      )}
    </>
  );
}
